<template>
  <div v-if="stepData.text_step">
    <b-alert
      variant="danger"
      class="p-2"
      show
    >
      <div>
        <h3>{{ stepData.text_step && stepData.text_step[0] && stepData.text_step[0].header }}</h3>
        <div v-if=" stepData.text_step && stepData.text_step[0] && $route.path !== '/'+$i18n.locale+stepData.text_step[0].url">
          <hr>
          <p>
            {{ stepData.text_step && stepData.text_step[0]&& stepData.text_step[0].description }}
          </p>
          <b-button
            :to="'/'+$i18n.locale+stepData.text_step[0].url"
          >
            {{ stepData.text_step && stepData.text_step[0] && stepData.text_step[0].button_name }}
          </b-button>
        </div>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue'

export default {
  components: { BAlert },
  props: {
    stepData: {
      default: () => ({
        name: '',
        button_name: '',
        url: '',
        header: '',
        description: '',
      }),
      type: Object,
    },
  },
  data() {
    return {

    }
  },
}
</script>
