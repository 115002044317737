<template>
  <div>
    <resendEmail
      v-if="nextStep && nextStep.name === 'verify_email'"
      :card="card"
    />
    <registerStep
      v-else-if="nextStep && nextStep.text_step"
      :step-data="nextStep"
    />
  </div>
</template>
<script>
import resendEmail from '@/views/components/registerSteps/resendEmail.vue'
import registerStep from '@/views/components/registerSteps/registerStep.vue'

export default {
  components: { resendEmail, registerStep },
  props: {
    card: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      // there is predefined steps that user can go throw, but the order can be change from admin
      steps: [],
      nextStep: {},
    }
  },
  watch: {
    '$store.state.auth.steps': {
      handler() {
        const [nextStep] = this.$store.state.auth.steps.filter(step => !step.done_step).sort((a, b) => a.priority - b.priority)
        this.nextStep = nextStep
      },
      deep: true,
    },
  },
}
</script>
