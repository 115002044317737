<template>
  <b-row>
    <b-col
      v-if="card"
      cols="12"
    >
      <b-card>
        <b-card-body>
          <b-row
            align-h="center"
            align-v="center"
            style="flex-direction:row-reverse"
          >
            <b-col
              cols="12"
              sm="5"
              md="3"
              class="mb-3 mb-sm-0 d-flex justify-content-center"
            >
              <feather-icon
                variant="success"
                icon="CheckCircleIcon"
                size="150"
                color="#94d56c"
              />
            </b-col>
            <b-col
              cols="12"
              sm="7"
              md="9"
            >
              <h3 class="font-weight-bold mb-4">
                {{ $t('user_no_active.register_success_confirm_email') }}
              </h3>
              <h3> {{ $t('user_no_active.description1') }}</h3>
              <h3> {{ $t('user_no_active.description2') }}</h3>
              <h3 class="text-danger">
                {{ $t('user_no_active.email_process') }}
              </h3>
              <div class="mt-5 text-center">
                <h4> {{ $t('user_no_active.email_resend') }}</h4>
                <b-button
                  class="text-nowrap"
                  :disabled="sendMaliLoading || !(remainingTime.minutes === 0 && remainingTime.seconds === 0)"
                  variant="primary"
                  @click="resendActivationEmail"
                >
                  <span>{{ $t('user_no_active.resend_email_btn') }}</span>
                  <span
                    v-if="remainingTime.minutes > 0 || remainingTime.seconds > 0"
                    class="mx-1"
                    dir="rtl"
                  >

                    {{ formatTime(remainingTime.seconds) }}
                    :
                    {{ formatTime(remainingTime.minutes) }}
                  </span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </b-col>
    <b-alert
      show
      variant="danger"
      class="p-2 w-100"
    >
      <div>
        <h2>{{ $t('user_no_active.register_success_confirm_email') }}</h2>
        <p>{{ $t('user_no_active.description1') }}</p>
        <hr>
        <p class="mb-2">
          {{ $t('user_no_active.email_process') }} {{ $t('user_no_active.email_resend') }}
        </p>
        <b-button
          :disabled="sendMaliLoading || !(remainingTime.minutes === 0 && remainingTime.seconds === 0)"
          variant="success"
          @click="resendActivationEmail()"
        >
          <span>{{ $t('user_no_active.resend_email_btn') }}</span>
          <span
            v-if="remainingTime.minutes > 0 || remainingTime.seconds > 0"
            class="mx-1"
          >

            {{ formatTime(remainingTime.seconds) }}
            :
            {{ formatTime(remainingTime.minutes) }}

          </span>
        </b-button>
      </div>
    </b-alert>
  </b-row>
</template>

<script>
import ProfileApis from '@/services/apis/profile'

const profile = new ProfileApis()
export default {
  props: {
    card: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      sendMaliLoading: false,
      remainingTime: {
        minutes: 0,
        seconds: 0,
      },
      remainingSeconds: 0, // Initial number of seconds (5 minutes)
    }
  },
  mounted() {
    profile.get().then(res => {
      if (!res.data.results.user.isActive) {
        this.resendActivationEmail()
      }
    }).finally(() => {
      this.show = false
    })
  },

  methods: {
    resendActivationEmail() {
      this.sendMaliLoading = true
      profile.reSendActivationMail({ email: this.$store.state.auth.user.email }).then(res => {
        this.remainingSeconds = res.data.results.remained_expiration_time
        if (this.remainingSeconds) {
          this.updateCountdown()
        }
        this.$swal({
          title: `${this.$t('activationEmailSended')}`,
          icon: 'success',
          timer: 2500,
          showConfirmButton: true,
        })
      }).catch(err => {
        if (err.data.status === 406) {
          this.remainingSeconds = err.data.results.remained_expiration_time
          if (this.remainingSeconds) {
            this.updateCountdown()
          }
        }
      })
        .finally(() => {
          this.sendMaliLoading = false
        })
    },
    formatTime(value) {
      return value.toString().padStart(2, '0')
    },
    updateCountdown() {
      if (this.remainingSeconds > 0) {
        this.remainingTime.minutes = Math.floor(this.remainingSeconds / 60)
        this.remainingTime.seconds = this.remainingSeconds % 60
        this.remainingSeconds -= 1

        setTimeout(this.updateCountdown, 1000) // Call itself after 1 second
      } else {
        this.remainingTime.minutes = 0
        this.remainingTime.seconds = 0
      }
    },
  },

}
</script>
