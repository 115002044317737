<template>
  <div
    class="app-content content"
    :class="[{ 'show-overlay': $store.state.app.shallShowOverlay }, $route.meta.contentClass]"
  >
    <RegisterSteps />
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div
      class="content-wrapper"
      :class="contentWidth === 'boxed' ? 'container p-0' : null"
    >
      <slot name="breadcrumb">
        <app-breadcrumb />
      </slot>
      <div class="content-body">
        <transition
          :name="routerTransition"
          mode="out-in"
        >
          <slot />
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'
import AppBreadcrumb from './AppBreadcrumb.vue'
import RegisterSteps from '@/views/components/registerSteps/registerSteps.vue'

export default {
  components: {
    AppBreadcrumb,
    RegisterSteps,
  },
  setup() {
    const { routerTransition, contentWidth } = useAppConfig()

    return {
      routerTransition, contentWidth,
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },
    isDashboard() {
      return this.$route.name === 'dashboard'
    },
    isActive() {
      return this.$store.state.auth.user.isActive
    },
    isInHiddenRoute() {
      if (this.cardInfo.nextStep.hiddenRoutes) {
        return this.cardInfo.nextStep.hiddenRoutes.find(item => {
          console.log(this.$route.fullPath, `/${this.$i18n.locale}/${item}`)
          return (item.name ? (this.$route.name === item.name) : (this.$route.fullPath === `/${this.$i18n.locale}${item}`))
        })
      }
      return false
    },
  },
  watch: {
    lang(val, old) {
      const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}/${old}/${process.env.VUE_APP_EXCHANGE_PAGE}`}']`)
      if (exchangeElement) {
        exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${val}/${process.env.VUE_APP_EXCHANGE_PAGE}`
      }
    },
    isActive() {
      this.exchangeLinkAction()
    },
  },
  mounted() {
    const exchangeElement = document.querySelector(`a[href='${`${process.env.VUE_APP_EXCHANGE_ADDRESS}`}']`)
    if (exchangeElement) {
      exchangeElement.target = '_self'
      exchangeElement.href = `${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/${process.env.VUE_APP_EXCHANGE_PAGE}`
    }
    this.exchangeLinkAction()
    this.crispCHat()
  },

  methods: {
    crispCHat() {
      const supportCHat = process.env.VUE_APP_SUPPORT_CHAT
      if (supportCHat && supportCHat === 'true') {
        const siteId = process.env.VUE_APP_SUPPORT_CHAT_WEBSITE_ID
        window.crisp = []
        window.CRISP_WEBSITE_ID = siteId;
        (function () {
          const d = document
          const s = d.createElement('script')
          s.src = 'https://client.crisp.chat/l.js'
          s.async = 1
          d.getElementsByTagName('head')[0].appendChild(s)
        }())
      }
    },

    exchangeLinkAction() {
      const exchangeElement = document.querySelector(`a[href='${process.env.VUE_APP_EXCHANGE_ADDRESS}/${this.$i18n.locale}/${process.env.VUE_APP_EXCHANGE_PAGE}']`)
      if (exchangeElement) {
        if (this.isActive === '0') {
          exchangeElement.onclick = () => {
            this.$swal({
              title: this.$t('exchange_menu_click_user_no_active'),
              icon: 'warning',
              timer: 3000,
              showConfirmButton: false,
            })
            return false
          }
          exchangeElement.classList.add('disabled-link')
        } else {
          exchangeElement.removeAttribute('onclick')
          exchangeElement.classList.remove('disabled-link')
        }
      }
    },
  },
}
</script>
